import React, { useRef } from "react";
import { PageProps } from "gatsby";
import { LayoutProvider, Wrapper } from "../components/layout";
import RegisterSafeFormBySteps from "../components/sections/RegisterSafeFormBySteps";
import { stackStyled, Typography } from "../theme";
import { StackOnBreadcrumbs } from "../atoms";
import { ResponsiveContainer } from "../atoms/Containers";
import { useViewPortSize } from "../hooks";

interface Props {}

const BreadCrumbsContainer = stackStyled(
  "div",
  (props: { isMobile: boolean }) => ({
    position: "absolute",
    top: props.isMobile ? "10px" : "30px",
    left: 0,
    width: props.isMobile ? "100%" : "580px",
  })
);

const MainWrapper = stackStyled(
  Wrapper,
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: "flex",
    maxWidth: "1280px",
    width: props.isMobile ? "95%" : props.isTablet ? "90%" : "100%",
    justifyContent: "center",
    padding: props.isMobile ? "100px 10px" : "50px 10px",
    marginLeft: "auto",
    marginRight: "auto",
    overflowX: "hidden",
    textAlign: "center",
  })
);

export const RegisterProduct: React.FC<PageProps> = (props: PageProps) => {
  // HOOKS
  const [isMobile, isTablet] = useViewPortSize();

  // STATE

  // HANLDERS && FUNCTIONS
  let addTitle = props.location.search
    ? props.location.search.split("=")[1].split("+").join(" ")
    : "";
  
  React.useEffect(() => {
    const timer = setTimeout(() => {
      window.location.replace(
        "https://customersupport.stack-on.com/portal/newticket"
      );
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  //   // RENDER
  return (
    <LayoutProvider
      seo
      locationSearch={props.location.search}
      titleExt={addTitle}
    >
      <MainWrapper isMobile={isMobile} isTablet={isTablet} noScroll>
        <BreadCrumbsContainer isMobile={isMobile}>
          <StackOnBreadcrumbs
            pages={[
              { title: "Home", href: "/" },
              { title: "Customer service", href: "/customer-service" },
              { title: "Register your product", href: "/product-registration" },
            ]}
          />
        </BreadCrumbsContainer>
        <div>
          <Typography
            $fontFamily="Montserrat"
            $weight={700}
            $tagType={isMobile ? "h3Mob" : "h3"}
            $color={"#2A3C24"}
            $margin={isMobile ? "80px 0 5px 0" : "120px 0 5px 0"}
            $textAlign="center"
            $ariaLabel={"Product Registration"}
          >
            Product Registration has moved
          </Typography>
          <Typography
            $fontFamily="Montserrat"
            $weight={400}
            $tagType={isMobile ? "labelMob" : "label"}
            $color={"#474B44"}
            $margin={isMobile ? "0 0 40px 0" : "0 0 60px 0"}
            $textAlign="center"
            $ariaLabel={"Subtitle Producto Registration"}
          >
            Please wait while you're being redirected to the new experience
          </Typography>
          {/* <RegisterSafeFormBySteps /> //Uncomment if form is needed it */}
        </div>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default RegisterProduct;
